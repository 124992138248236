@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
}