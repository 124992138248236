@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .mirrored {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
  }

  .medium-size {
    width: 40%;
  }

  .medium-size:before {
    padding-top: 100%;
    content: "";
  }

  .full-size {
    height: 95%;
  }

  .full-size:before {
    padding-left: 100%;
    content: "";
  }

  .big-size {
    height: 83.333333%;
  }

  .big-size:before {
    padding-left: 100%;
    content: "";
  }

  .padding-squared-aspect-ratio {
    padding-left: calc(50vh - 3.125rem);
    padding-right: calc(50vh - 3.125rem);
  }
}