@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .balloon {
    @apply px-2 py-2 text-center border-2 border-solid md:px-6 md:py-3 rounded-xl focus:outline-none;
  }

  .balloon + .left {
    @apply rounded-bl-none;
  }

  .balloon + .right {
    @apply rounded-br-none;
  }
}